@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,400;1,700&family=Merriweather:ital,wght@0,400;0,700;1,400;1,700&display=swap');
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Lato', apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    font-size: 16px;
    line-height: 1.3em;
    color: #737373;
    overflow-x: hidden; 
  }

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

input::placeholder {
  color: #3d3d3d;
  font-size: 16px;
  opacity: 1; /* Firefox */
}

input::-webkit-input-placeholder {
  color: #3d3d3d;
  font-size: 16px;
}

input::-moz-placeholder {
  color: #3d3d3d;
  font-size: 16px;
}

input::-ms-placeholder {
  color: #3d3d3d;
  font-size: 16px;
}

input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #3d3d3d;
  font-size: 16px;
}

input::-ms-input-placeholder { /* Microsoft Edge */
  color: #3d3d3d;
  font-size: 16px;
}

/* Next.js image override */
.custom-img {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
}

.unset-img {
  width: 100%;
}

.unset-img > span {
  position: unset !important;
}

h1, h2 {
  font-family: 'HCo Tungsten';
  line-height: 1.2em;
}
h1 {
  font-weight: 300;
}
h2 {
  font-weight: 400;
}

h1.title-font {
  font-size: 94px;
  letter-spacing: 3px;
}
h2.title-font {
  font-weight: 300;
  letter-spacing: 2px;
}

.lato-title {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.lato-h1 {
  font-size: 40px;
  line-height: 1.3em;
}

.lato-h2 {
font-size: 52px;
line-height: 1.2em;
letter-spacing: -1px;
}

.lato-h2-sml {
  font-size: 36px;
  line-height: 1.2em;
  letter-spacing: -1px;
}

.text-6xl {
  font-size: 68px;
}
.text-5xl {
  font-size: 3rem;
  line-height: 3rem;
}
.text-gray-900, .text-gray {
  color:#737373;
}
.text-gray-heading {
  color:#3D3D3D; 
}
.text-blue {
 color: #3E7998;
}
.text-dark-blue {
  color:#00355B;
}
.text-orange {
  color:#EA7024;
}
.font-bold {
  font-weight: bold;
}
.font-light {
  font-weight: 100;
}
.sub-header {
  font-size: 24px;
  line-height: 1.3em;
}
.font-lightish {
  font-weight: 300;
}
.inline-block {
  display: inline-block;
}
.hover\:text-gray-lt:hover {
  --tw-text-opacity: .6;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.hover\:text-gray-orange:hover {
  --tw-text-opacity: 1;
  color: #EA7024;
}

.mobile-only, .main-menu a.mobile-only {
  display: none;
}

.break-inside {
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
       break-inside: avoid;
}

/* BG Styles ---------------------*/
.bg-center {
  background-position: center;
}

.bg-right-top {
background-position: right center;
}

.bg-gray-100 {
  background-color: #EAEBEC;
}

/* Spacing Class Overrides --------------------*/

.mr-6 {
  margin-right: 2em;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mt-5 {
  margin-top: 2em;
}

.mt-10 {
  margin-top: 4rem;
}
.mb-5 {
  margin-bottom: 1.6rem;
}

.mb-9 {
  margin-bottom: 3rem;
}

.mb-10 {
  margin-bottom: 4rem;
}

.my-5 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.pt-12 {
  padding-top: 2rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pb-12 {
  padding-top: 2rem;
}

.pb-16 {
  padding-bottom: 4em;;
}

.pb-24 {
  padding-bottom: 6em;;
}

.pr-16 {
  padding-right: 4rem;
}

.pr-24{
  padding-right: 6rem;
}

.pl-24{
  padding-left: 6rem;
}

.py-6 {
  padding-top: 4em;
  padding-bottom: 4em;
}

.py-18 {
  padding-top: 6em;
  padding-bottom: 6em;
}

.py-30 {
  padding-top: 8em;
  padding-bottom: 8em;
}

.py-40 {
  padding-top: 9em;
  padding-bottom: 9em;
}

.py-50 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.py-60 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.py-64 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-3 {
  gap: 0.75rem;
}

.items-start {
align-items: flex-start;
}

.grow-1 {
  flex-grow: 1;
}

/* Width Classes ----------------------*/
.max-w-md {
    max-width: 28rem;
}

/* Header Styles -----------------------*/

.logo {
  display: flex;
  align-items: baseline;
  font-size: 36px;
}

.logo span {
font-size: inherit;
}

.logo .font-bold {
  font-size: 26px;;
}

.primary-header:hover .logo span {
  color:#00355B;
}

.primary-header:hover button svg path, .primary-header:hover a svg path {
  fill:#00355B;
}

.pursuit-header nav {
  display: none;
}

.pursuit-header path {
  fill:#EA7024;
}

.pursuit-wrapper:before {
  width: 1px;
  height: 120%;
  position: absolute;
  left: 50%;
  top: -10%;
  margin-left: -1px;
  background: #232656;
  content: "";
}
.quiz .lato-title {
  font-size: 18px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.quiz .btn-orange {
  min-width: 100%;
  width:100%;
}
.quiz .btn-orange.w-svg svg {
margin-left: 0;
}
.rotate-svg svg {
  transform: rotate(180deg);
      text-align: left;
      margin-left: 0;
}

.answered .font-bold, .answered p {
  display: none;
}

.answered .correct-image {
  display: block;
}

.contribute .search-form, .my-library .search-form {
display: none;
}

.contain-submit {
  float: right;
  width: 160px;
}

.contain-submit a {
  min-width: 160px;
  width: 160px;
  margin-top: 10px;
}

.my-library #home-hero , .timeline #timeline-hero {
     height: 60vh;
    min-height: 470px;
    }

.hero-bg {
  background: rgb(74,140,176);
  background: -moz-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
  background: linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
/* Menu -------------------------*/

.main-menu a {
color:#a8c1cf;
}

.primary-header::before {
  position: absolute;
  background: #fff;
  opacity: 0;
  left: 0;
  right: 0;
  height: 85px;
  content: "";
  transition: all ease .5s;
}

.primary-header:hover::before {
  opacity: .7;
}
.primary-header .container {
  position: relative;
  z-index: 1;
}

.primary-header:hover .main-menu a {
color: #3E7998; 
}

.primary-header:hover .main-menu a:hover, .main-menu a:hover {
  color:#00355B;
}
/* Button Styles -----------------------*/

.btn-orange {
  background: #EA7024;
  border:2px solid #EA7024;
  font-size: 16px;
  transition: all ease .5s;
  min-width: 220px;
}

.btn-orange:hover {
  color:#EA7024;
  background: #fff;
}

.btn-orange:hover svg path, .video-btn svg:hover path {
  fill: #EA7024;
}
.btn-padding {
  padding: 8px 20px;
}
.w-svg svg {
  margin-left: 50px;
  margin-top: 5px;
}

.upload-button svg {
  transform: rotate(-90deg);
}

.explore {
    position: absolute;
    bottom: 25px;
    width: 80px;
    left: 50%;
    margin-left: -40px;
}
.explore svg {
  transform: rotate(90deg);
  text-align: center;
  margin: 0 auto;
  height: 16px;
}

.explore h4 {
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 16px;
}
.protiviti-gradient {
background: rgb(74,140,176);
background: -moz-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
background: linear-gradient(90deg, rgba(74,140,176,1) 0%, rgba(0,53,91,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4a8cb0",endColorstr="#00355b",GradientType=1);
}

.orange-gradient {
  background: rgb(247,148,40);
background: -moz-linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
background: linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f79428",endColorstr="#d74b29",GradientType=1);
}
.orange-gradient-border-top {
  position: relative;
}
.orange-gradient-border-top:before {
  background: rgb(247,148,40);
background: -moz-linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
background: linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f79428",endColorstr="#d74b29",GradientType=1);
content: "";
position: absolute;
width:100%;
height:8px;
top:0;
left: 0;
}

/* Home ----------------------------*/ 
.Home_main__nLjiQ .search-form {
  display: none;
}

.home-story {
  cursor: pointer;
}
.home-story .wrapper {
  transition: all ease .5s;
}

.home-story:hover .wrapper {
  transform: translate(0, -5px);
  box-shadow: 0 0px 8px rgb(0 0 0 / 22%);
} 

.home-story .unset-img {
  overflow: hidden;
}

.search-form {
  max-width: 420px;
  margin: 0 auto;
}

.search-form input {
  width: 100%;
}

.search-form svg path {
  fill:#3d3d3d;
}

/* Video Hero ------------------------*/
.play-video-btn {
  width: 25px;
  height: 25px;
  margin-top: 0 !important;
}

.popupbox .popupbox-wrapper {
  border-radius: 3px;
  overflow: hidden;
  max-width: max-content;
  min-width: 300px;
  box-shadow: 0 0 20px rgb(0 0 0 / 90%);
  background-color: #fff;
}

.popupbox-wrapper .popupbox-content {
  padding: 0;
}

.popupbox-wrapper .popupbox-content .container {
  max-width: 100%;
}

.video-popup {
  width: 100%;
  max-width: 1023px;
}

.video-popup video {
  width: auto;
  overflow: hidden;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
  max-height: 60vh;
  min-height: 300px;
}
/* Stories ------------------------*/

.story {
  transition: all ease .6s;
  cursor: pointer;
}
.story h2.lato-title {
    font-size: 26px;
    transition: all ease .6s;
}

.story h5.title-font, .date, .author {
    font-size: 13px;
    line-height: 1.1em;
    letter-spacing: 0;
    transition: all ease .6s;
}

.single-story .date, .single-story .author {
  font-size: 16px;
}

.vmh {
  max-height: 500px;
  width: 100%;
  background: #000000;
}

.single-story p, .single-story ol, .single-story ul {
	margin-bottom: 20px;
	line-height: 1.5em;
}

.single-story ul, .single-story ol {
  padding-left: 20px;
}

.single-story p a,
.single-story li a {
  color: #3E7998;
}

.single-story li {
  margin-bottom: 12px;
  list-style: disc;
}

.story p {
    line-height: 1.4em;
}

.story:hover h2.lato-title, .story:hover h5.title-font {
  color:#EA7024;
}
.story .unset-img {
  transition: all ease .5s;
}
.story:hover .unset-img {
  transform: translate(0, -5px);
  box-shadow: 0 0px 7px rgb(0 0 0 / 18%);
} 

.like svg,.library svg {
  transition: transform 0.5s;
}
.library:hover svg {
  transform: scale(0.7);
}
.like:hover svg {
  -webkit-animation: heartbeat 1s linear infinite;
  -o-animation: heartbeat 1s linear infinite;
  -moz-animation: heartbeat 1s linear infinite;
  -ms-animation: heartbeat 1s linear infinite;
	animation: heartbeat 1s linear infinite;
}
.like:hover svg path, .library:hover svg path , .library:hover svg rect{
  fill:#EA7024;
}

.search-form button {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 50px;
  cursor: pointer;
  text-align: center;
}

.search-form button svg {
  margin:0 auto;
}

@keyframes heartbeat {
  0%
  {
    transform: scale(.7);
  }
  100%
  {
    transform: scale(1);
  }
}

@-webkit-keyframes heartbeat {
  0%
  {
    transform: scale(.7);
  }
  100%
  {
    transform: scale(1);
  }
}
.load-more-wrap svg {
  transform: rotate(90deg);
}

#select-container {
  max-width: 260px;
  width: 100%;
  margin-bottom: 40px;
}

#select-container .react-select__control {
  border: none;
  border-radius: 0;
  background-color: #00345b;
  height: 45px;
  box-shadow: none;
}

#select-container .react-select__control svg path {
  fill: #ffffff;
}

.react-select__control .react-select__value-container::before {
  content: 'Filter';
  color: #fff;
  position: absolute;
  left: 20px;
}

.react-select__control .react-select__placeholder {
  text-indent: -9999999px;
}

#select-container .react-select__menu {
  background-color: #00345b;
  color: #ffffff;
  margin-top: 0;
  border-radius: 
  0;
}

#select-container .react-select__menu-list {
  max-height: initial;
  overflow-y: initial;
}

#select-container .react-select__menu .react-select__group > div:nth-of-type(2) {
  height: 0;
  opacity: 0;
  display: none;
}

#select-container .react-select__menu .react-select__menu-list .react-select__group .collapsed {
  height: 100%;
  opacity: 1;
  display: block;
}

#select-container .react-select__menu .react-select__menu-list .react-select__group .collapsed .react-select__option {
  padding: 8px 12px 8px 22px;
}

#select-container .react-select__group .react-select__option--is-selected,
#select-container .react-select__group .react-select__option--is-focused {
   background: #4C7A9A;
 }

 #select-container .react-select__group .react-select__group-heading {
   text-transform: none;
   color: #ffffff;
   font-size: 16px;
   position: relative;
 }

 #select-container .react-select__group .group-heading-wrapper {
   position: relative;
 }

 #select-container .react-select__group .group-heading-wrapper svg {
  position: absolute;
  right: 5px;
  top: 0;
  transform: rotate(-90deg);
  fill: #ffffff;
}

#select-container .react-select__group .group-heading-wrapper.active svg {
  transform: rotate(0deg);
}

/* Flex Class Overrides --------------------*/
.content-between {
  justify-content: space-between;
}

.row-reverse {
  flex-direction: row-reverse;
}

/* Footer Styles -------------------*/

.footer-top {
  background:#3F3F3F;
}

.footer-nav a {
  display: block;
}

/* Stories masonry grid */
@layer utilities {
  @variants responsive {
    .masonry {
        column-gap: 1.5em;
        column-count: 1;
    }
    .masonry-sm {
        column-gap: 1.5em;
        column-count: 2;
    }
    .masonry-md {
        column-gap: 1.5em;
        column-count: 3;
    }
    .break-inside {
        break-inside: avoid;
    }
  }
}

.infinite-scroll-component {
  -moz-column-gap: 1.5em;
    column-gap: 1.5em;
    -moz-column-count: 1;
    column-count: 1;
}

@media (min-width: 768px) {
  .infinite-scroll-component {
      -moz-column-gap: 1.5em;
      column-gap: 1.5em;
      -moz-column-count: 2;
      column-count: 2;
  }
}
@media (min-width: 1024px) {
  .infinite-scroll-component {
      -moz-column-gap: 1.5em;
      column-gap: 1.5em;
      -moz-column-count: 3;
      column-count: 3;
  }
}

/* Pursuit Banner ------------------------*/
#pursuit:before {
  content: "";
  position: absolute;
  left:0;
  width:55%;
  height:100%;
  background-image: url(../public/images/pursuit-bg-full.png), rgb(247,148,40);
  background-image: url(../public/images/pursuit-bg-full.png), -moz-linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
  background-image: url(../public/images/pursuit-bg-full.png), -webkit-linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
  background-image: url(../public/images/pursuit-bg-full.png), linear-gradient(90deg, rgba(247,148,40,1) 0%, rgba(215,75,41,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f79428",endColorstr="#d74b29",GradientType=1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#pursuit .container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 26%;
  background: #fff;
  -webkit-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: skew( 
-35deg
);
  -ms-transform: skew(-35deg);
  transform: skew( 
-35deg
);
  height: 100%;
  width: 40%;
}

.pursuit-banner {
  max-width: 300px !important;
  min-width: 300px !important;
}

.bord-t-blue {
  border-top:5px solid #498CAF;
}

.letter, .answer {
  padding: 14px 0;
  background: #fff;
  text-align: center;
  box-sizing: border-box;
}
.letter {
    margin-right: 5%;
    width: 10%;
}

.answer {
  text-align: left;
  width: 85%;
  padding: 14px 20px;
}

.popupbox-wrapper.pursuit-results {
  background: #FFFFFF;
  border: 2px solid #FFFFFF;
  border-radius:0;
  font-size: 16px;
  transition: all .5s ease;
  min-width: 350px;
  box-shadow: none;
  text-align: center;
  padding: 30px;
  min-height: 260px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

.pursuit-results .results-big {
  color: #3E7998;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  max-width: 100%;
  font-size: 48px;
  line-height: 40px;
  margin: 0 auto 20px;
  font-family: 'HCo Tungsten';
}

.pursuit-results .results-small {
  color: #EA7024;
  font-weight: 500;
  font-size: 18px;
}

.game-preview {
  min-height: 216px;
}

.game-preview img {
  width: auto;
  object-fit: cover;
}

/* Timeline -----------------------*/
.event-hover .title-font {
  font-size: 20px;
  line-height: 1.2em;
  text-align: center;
}

.timeline-events-row {
  z-index: 8;
}
.event-content-wrapper {
  transition: ease all .5s;
}
.event-container.active {
  opacity: 1;
}
.event-content-wrapper {
  min-height: 450px;
  z-index: 8;
}
.event-content-wrapper.active .event-container {
position: relative;
  top: auto;
  left: 0;
  margin: 0 auto;
  padding-top: 20px;
}

.event-container img {
  width: 100%;
}

.event:hover {
  z-index: 9;
}

.contribute-arrow { 
  text-align: center;
  margin: 0 auto;
  padding-right: 0;
  left: 50%;
  margin-left: -26px;
  position: relative;
  top: -30px;
  width: 36px;
}
.contribute-arrow path {
  fill: #EA7024;
}

.main-event-container.py-30 {
  padding-top: 160px;
  padding-bottom: 150px;
}
    
.era-1 {
  background: url(../public/images/era-1-bg.png) no-repeat 75% center;
}
.era-2 {
  background: url(../public/images/era-2-bg.png) no-repeat 25% bottom;
}

.era-3 {
  background: url(../public/images/era-3-bg.png) no-repeat 75% center;
  background-size: contain;
}

.era-4 {
  background: url(../public/images/era-4-bg.png) no-repeat 25% bottom;
}

.era-5 {
  background: url(../public/images/era-5-bg.png) no-repeat right center;
  background-size: contain;
}

.era-6{
  background: url(../public/images/era-6-bg.png) no-repeat 25% bottom;
}

.era-7 {
  background: url(../public/images/era-7-bg.png) no-repeat 75% center;
  background-size: contain;
}
/* Contribute --------------------*/ 

.contribute-grid {
grid-template-columns: 66.666% 33.333%;
}

.contribute-form-buttons .btn-orange {
  min-width:202px;
}

.contribute .contribute-wrapper {
  max-width: 1110px;
}

/* Mobile Menu -------------------*/

.mobile-menu-button {
  position: absolute;
  top: 21px;
  left: 35px;
  z-index: 99999;
  display: none;
}
.mobile-button a {
  position: relative;
  z-index: 10;
  width: 40px;
  height: 40px;
  float: right;
  display: block;
}

.mobile-button a span.icon {
  position: absolute;
  top: 12px;
  left: 0;
  width: 80%;
  height: 2px;
  background-color: #00355B;
  transition: all .2s linear;
  -webkit-transition: all .2s linear;
  -moz-transition: all .2s linear;
  -o-transition: all .2s linear;
  margin: 10px 10%;
}

.mobile-button a span.icon:before {
  position: absolute;
  content: "";
  top: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  opacity: 1;
  visibility: visible;
  display: block;
  background-color: #00355B;
  transition: .3s;
  z-index: 99;
}

.mobile-button a span.icon:after {
  position: absolute;
  content: "";
  bottom: -8px;
  left: 0;
  width: 100%;
  height: 2px;
  opacity: 1;
  visibility: visible;
  display: block;
  background-color: #00355B;
  transition: .3s;
  z-index: 99;
}

.mobile-button a.active span.icon {
  background-color: transparent!important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: visible;
}

.mobile-button a.active span.icon:before {
  top: -50%;
  left: 0;
  -webkit-transform: rotate(
-45deg
)!important;
  -moz-transform: rotate(-45deg)!important;
  -ms-transform: rotate(-45deg)!important;
  transform: rotate(
-45deg
)!important;
}

.mobile-button a.active span.icon:after {
  bottom: 50%;
  transition: .3s;
  -webkit-transform: rotate(
45deg
)!important;
  -moz-transform: rotate(45deg)!important;
  -ms-transform: rotate(45deg)!important;
  transform: rotate(
45deg
)!important;
  z-index: 99;
}
.mobile-button a.active span.icon {
  background-color: transparent!important;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: visible;
}

/* Searchbar ----------------------*/
.MuiAutocomplete-root {
  padding-left: .35rem;
  padding-right: 4rem;
  width: 100%;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background: #ffffff;
  height: 3rem;
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

.search-form .MuiAutocomplete-root .MuiOutlinedInput-root {
  height: 3rem;
  padding: 0;
  line-height: 1rem;
}

.search-form .MuiInputLabel-root {
  line-height: 1.25rem;
  font-family: 'Lato', apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.search-form .MuiInputLabel-shrink {
  transform: translate(14px, 0) scale(.75);
  top: 3px;
}

.search-form .MuiOutlinedInput-notchedOutline  {
  border: none;
}

.search-form .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 0;
}

.search-form button.MuiButtonBase-root svg {
  margin-top: 21px;
  margin-right: 0;
}

.search-form button.MuiButtonBase-root:hover {
  background:none;
}

.search-form .MuiInputLabel-shrink {
  display:none;
}

/* Media Queries ------------------*/ 
@media (min-width: 640px) {
.sm\:text-1xl {
    font-size: 20px;
    line-height: 1.3em;
    font-weight: bold;
  }
header .sm\:mt-8 {
  margin-top: 8px;
}
}
@media (min-width: 1024px) {
.lg\:pl-24 {
    padding-left: 6rem;
  }
.lg\:w-1\/4 {
    width: 25%;
  }
} 
@media (min-width: 1536px) {
.container {
    max-width: 1280px;
  }
}
@media (max-width: 1050px) {
  .contribute-form-buttons .btn-orange {
    min-width: 185px;
  }
}
@media (max-width: 1023px) {
  #pursuit:before {
    width: 100%;
    height: 300px;
}
#pursuit .container {
  width: 100%;
  max-width: 100%;
  background: #fff;
  margin-top: 300px;
  flex-wrap: wrap;
  display: block;
}
}
@media (max-width: 1365px) {
  .main-event-container {
    max-width: 1024px;
  }
}
  @media (max-width: 1120px) {
    .main-event-container {
    max-width: 768px;
   } 
}
@media (max-width: 1023px) {
.event .event-hover, .event:nth-of-type(2) .event-hover, .event:nth-of-type(3) .event-hover, .event:nth-of-type(4) .event-hover, .event:nth-of-type(5) .event-hover, .event:nth-of-type(6) .event-hover {
  left: 10px;
  width: auto;
  margin: 0;
  position: relative !important;
  top: auto !important;
  left: auto !important;
}
.event-content-wrapper {
  display: none;
}
.event-hover .title-font {
  padding-left: 0;
  padding-top: 7px;
  text-align: left;
}
.sm\:w-full-alt {
  width: 100% !important;
  padding: 0 !important;
}
.main-event-container.py-30 {
  padding-top: 110px;
  padding-bottom: 110px;
}
.timeline-events-row {
  position: relative;
  padding-left: 4.5rem;
}
.lato-h2 {
  font-size: 36px;
}
}

@media (max-width: 1022px) {
.quiz {
  width: 50%;
}

}
@media (max-width: 960px) {
  .contribute-form-buttons .btn-orange {
    min-width: 140px;
    width: 140px;
  }
}
@media (max-width: 768px) {
  .mobile-only, .main-menu a.mobile-only {
    display: block;
  }
  .upload-button svg {
    margin-left: 29px;
  }
  .mobile-flex-col {
    flex-direction: column;
  }
  
  h1.title-font {
    font-size: 64px;
  margin-top: 40px;
  }
  .mobile-menu-button {
    display: block;
  }
  .main-menu a {
    font-size: 20px;
    color: #00426B;
    padding: 1.5rem 6rem;
    display: block;
    border-bottom: 1px solid #e6e6e6;
    width: 100%;
  }
  .primary-header::before {
    opacity: .7;
}

.main-menu {
  display: none; 
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 99998;
  background: #fff;
  top:0;
  left: 0;
  transition: all ease .5s;
}

.open.main-menu {
  display: block;
  padding-top: 9rem;
}

.primary-header .logo span {
  color: #00355B;
}
.primary-header button svg path, .primary-header a svg path {
  fill: #00355B;
}

.primary-header .logo {
  position: absolute;
  left: 50%;
  margin-left: -40px;
  top: 25px;
}
.primary-header .container {
  justify-content: flex-end;
}
.primary-header .container button {
  margin-top: 5px;
}

.event-content-wrapper {
  position: absolute;
  top: 0px;
  width: 50%;
  left: 50%;
}
.timeline-events-row {
  padding-left: 2.75rem;
}

.main-event-container.px-5 {
  padding-left: 3rem;
  padding-right: 3rem;
}
}
@media (min-width: 768px) {
/* .mlr-6 {
  margin-right: 1em;
  margin-left: 1em;
} */
header.logo {
  width: 25%;
}
header.nav {
  width: 50%;
}
.main-menu a {
  width: 115px;
  display: inline-block;
  margin: 0;
  text-align: center;
}
.main-menu a:first-child {
  text-align: right;
}
.main-menu a:last-child {
  text-align: left;
}
header.account-btn {
  width: 25%;
  text-align: right;
}
}
@media (max-width: 767px) {
.quiz {
  width: 100%;
}
.contain-submit {
  float: none;;
}
.pursuit-header nav {
  display: block;
}
.pursuit-wrapper:before {
  display: none;
}
.answered .correct-image {
  display: none;
}
.sm\:pt-0 {
  padding-top: 0;
}
.sm\:mt-8 {
  margin-top: 8px;
}
.pursuit_button_container {
  margin-top: 0;
}
.pursuit_button_container div {
  margin-top: 0;
}
.stories-panel-header,
.stories-panel-subheader {
  padding-right: 180px;
}
.stories-panel-header {
  font-size: 52px;
}
.stories-panel-subheader {
  font-size: 24px;
}
}

/* Slick React -------------------*/

.slick-arrow.slick-prev {
  top: -30px;
  right: 80px;
  left: initial;
  background: #3A7492;
  height: 50px;
  width: 50px;
}

.slick-arrow.slick-prev::before {  
  content: url(../public/images/button-arrow-left.svg);
  width: 26px;
  height: 20px;
  position: absolute;
  left: 11px;
  top: 13px;
}

 .slick-arrow.slick-next {
  top: -30px;
  right: 15px;
  background: #3A7492;
  height: 50px;
  width: 50px;
}

.slick-arrow.slick-next::before {
  content: url(../public/images/button-arrow.svg);
  width: 26px;
  height: 20px;
  position: absolute;
  left: 11px;
  top: 13px;
}

.slick-arrow.slick-prev:hover,
.slick-arrow.slick-next:hover {
  background: #ADC5D1;
}

.slick-arrow.slick-prev:focus,
.slick-arrow.slick-next:focus {
  background: #3A7492;
}